
// standard html elements

*{
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  font: {
    family: $font-stack;
    size: $base_font_size;
    weight: $base-font-weight;
  }
  color: $base-font-color;
  line-height: $base_line_height;
  background-color: $base-background-color;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    max-width: $full_width;
  }


// navigation styling
nav {
  width: $nav_width;
  min-width: 14rem;
  padding-left: 2rem;
  h2 {
    font: {
      weight: bold;
      size: 2rem;
    }
    color: $logo_color;
    text-decoration-color: $logo_color;
    line-height: 1;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  ul {
    list-style: none;
    padding: 0 0;
    font: {
      weight: $base-font-weight - 100;
    }
    line-height: 1.3rem;
    li {
      margin: 0.3rem 0;
    }
  }
  .section_start {
    margin-top: 1rem;
    font: {
      weight: $base-font-weight + 100;
    }
  }
  footer {
    font-size: .5rem;
    line-height: .8rem;
  }
  .copy {
    font-style: italic;
    font-size: 0.7rem;
  }
}

// Main content block
main {
  min-width: 18rem;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  word-wrap: break-word;
}

// main article block
article {
  margin: 0 auto 8rem;
}

// content elements
p, h1, h2, h3, h4, h5, h6, ul, ol {
  max-width: $column_width;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  line-height: 2rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

audio {
  margin: 2rem auto;
  display: table;
}


// links styling
a {
  color: $link_color;
  text-decoration: none;
}

a:focus, a:hover {
  text-decoration: underline;
  background-color: cornsilk;
}


// images, figures and captions
img {
  display: block;
  max-width: 100%;
  margin: 1rem 0 3rem;
}

figure {
  display: block;
  max-width: $column_width;
  margin: 1rem auto 4rem;
  img {
    margin: 0 auto 0.5rem;
  }
}

.caption, figcaption {
  font-size: 0.8rem;
  font-style: italic;
  text-align: center;
  margin-bottom: 2rem;
  color: $base-font-color;
}


// blockquotes
blockquote p {
  font-style: italic;
  padding-left: 0;
  padding-right: 0;
}


// tables
table {
  width: 100%;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  overflow-x:auto;
}

th, td {
  text-align: left;
  padding: .25rem .25rem;
  border: 1px solid #eee;
}

tbody tr:nth-child(odd){
  background-color: #eee;
}

// code
code {
  color: red;
  background-color: #fff9f9;
}

pre {
  max-width: 100%;
  background-color: #fff9f9;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: scroll;
}

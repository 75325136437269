// Set site-wide default values
$font-stack: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base_font_size: 20px;
$base-font-weight: 400;
$base-font-color: black;
$base_line_height: 1.8rem;
$base-background-color: #fff;
$logo_color: crimson;
$link_color: darkblue;
$column_width: 700px;
$nav_width: 280px;
$full_width: 1400px;
$breakpoint: 760px;
// end site-wide default values

@import 'normalize';
@import 'base';


// styling generic classes
// disable underline in logo and home image captions
.logo:hover, .home figure a {
  text-decoration: none;
}


// full_width class styling, used for images, but can also be added to any other element
.full_width {
  max-width: $full_width;
}

// styling individual pages
// timeline page styling
.timeline {
  h1 {
    margin-bottom: 4rem;
  }
  h2 {
    margin-top: 2rem;
    margin-bottom: 0.75rem;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding-left: 5rem;
  }
  ul {
    margin-top: 0.25rem;
    padding-left: 5rem;
    list-style: none;
  }
  li {
    margin-bottom: 1rem;
  }
}


// individual work page styling
.work {
  h1 {
    margin-bottom: 1rem;
  }
}

.works_head {
  margin-top: 1rem;
}


// styling the works index pages
.works_list {
  max-width: 100%;
  h1 {
    margin-bottom: 4rem;
  }
}


// styling used for each work item on the works index pages
.works_item {
  margin-top: 0;
  margin-bottom: 5rem;
  img {
    margin: 0;
  }
  h2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 2rem;
  }
  p {
    margin: 0;
    font: {
      weight: $base-font-weight - 100;
      size: 0.9rem;
    }
  }
}


// news page styling
.news_image_width {
  width: $column_width
}


// formatting the date for news posts
.post_date {
  margin-top: 0;
  margin-bottom: 3rem;
  font: {
    weight: $base-font-weight - 100;
    size: 0.9rem;
  }
}


// social icons in the navigation bottom
.social_icons svg {
  display: inline;
  max-height: 1.2rem;
  max-width: 2rem;
  color: silver;
}

.social_icons:hover {
  background-color: $base-background-color;
}

// responsiveness
// make stuff explicitly invisible on mobile or desktop
.desktop {
  @media (max-width: $breakpoint - 1) {
    display: none;
  }
}

.mobile {
  @media (min-width: $breakpoint) {
    display: none;
  }
}


// when on desktop, do this:
@media (min-width: $breakpoint) {
  body {
    flex-direction: row;
  }
  nav {
    padding-left: 1rem;
  }
  main {
    padding: 0 2rem;
  }
  blockquote p {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .works_list {
    width: $column_width;
  }
  .works_item {
    img {
      max-width: 15rem;
    }
  }
}

// for really big images on really big screens
@media (min-width: $full_width + ($nav_width / 2)) {
  figure {
    max-width: $full_width;
  }
}
